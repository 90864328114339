import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { NavbarContainer } from "./styles";
import Divider from "@mui/material/Divider";
import MenuIcon from "@mui/icons-material/Menu";
import Fade from "@mui/material/Fade";
import Grow from "@mui/material/Grow";

//icons
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { getInfoDisplay } from "../../firebase/firestore";

const animTimeout = 350;

export default function Navbar() {
  const [mobileMenu, setMobileMenu] = useState<"opened" | "closed">("closed");
  const [navLinksAnimState, setNavLinksAnimState] = useState({
    home: false,
    estimate: false,
    available: false,
    gallery: false,
    contact: false,
    logo: false,
  });
  const [infoDisplay, setInfoDisplay] = useState<{
    visibility: boolean;
    message: string;
  }>();

  const messageRef = useRef<any>(null);

  function triggerNextAnim(nextButton: string) {
    setNavLinksAnimState((prev) => ({ ...prev, [nextButton]: true }));
  }

  useEffect(() => {
    if (infoDisplay?.visibility) {
      document.body.style.paddingTop =
        messageRef.current.clientHeight + "px";
    }
  }, [infoDisplay]);


  useEffect(() => {
    
    window.addEventListener("resize", () => {
      try {
        if (infoDisplay?.visibility) {
          document.body.style.paddingTop =
            messageRef.current.clientHeight + "px";
        }
      } catch (e) {
        //do nothing
      }
    });
    setNavLinksAnimState((prev) => ({ ...prev, logo: true }));
    getInfoDisplay().then((info) => {
      console.log(info);
      info &&
        setInfoDisplay({ visibility: info.visibility, message: info.message });
    });
  }, []);

  const menuRef = useRef(null);
  useOnClickOutside(menuRef, closeMobileMenu);

  function closeMobileMenu() {
    setMobileMenu("closed");
  }

  return (
    <NavbarContainer>
      {infoDisplay?.visibility && (
        <div
          style={{
            width: "100%",
            height: "auto",
            background: "white",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            padding: "0.5rem",
            fontWeight: "600",
            textAlign: "center",
          }}
          ref={messageRef}
        >
          {infoDisplay.message}
        </div>
      )}
      <div className="content" ref={menuRef}>
        <div className="logo-and-hamburger">
          <Link
            className="logoLink"
            to="/"
            onClick={() => {
              backToTop();
              closeMobileMenu();
            }}
          >
            <Fade
              timeout={1500}
              in={navLinksAnimState.logo}
              onEntered={() => triggerNextAnim("estimate")}
            >
              {/* <img
                            className="logo"
                            src="/images/Simbolo_Branco.svg"
                            alt="Logo"
                        /> */}
              <h1 className="logotype">RATAZIMA</h1>
            </Fade>
          </Link>

          <IconButton
            className={`hamburger ${mobileMenu === "opened" && "hide"}`}
            onClick={() => setMobileMenu("opened")}
          >
            <MenuIcon />
          </IconButton>
          <IconButton
            className={`hamburger ${mobileMenu === "closed" && "hide"}`}
            onClick={() => setMobileMenu("closed")}
          >
            <CloseIcon />
          </IconButton>
        </div>

        <ul className={mobileMenu}>
          {/* <li>
            <Grow
              in={navLinksAnimState.home}
              timeout={animTimeout}
              onEntered={() => triggerNextAnim("about")}
            >
              <Link className="link" to="/" onClick={closeMobileMenu}>
                Home
              </Link>
            </Grow>
          </li> */}
          <li>
            <Grow
              in={navLinksAnimState.estimate}
              timeout={animTimeout}
              onEntered={() => triggerNextAnim("available")}
            >
              <Link className="link" to="/?section=orcamento" onClick={closeMobileMenu}>
                Orçamento
              </Link>
            </Grow>
          </li>
          <li>
            <Grow
              in={navLinksAnimState.available}
              timeout={animTimeout}
              onEntered={() => triggerNextAnim("gallery")}
            >
              <Link
                className="link"
                to="/disponiveis"
                onClick={closeMobileMenu}
              >
                Disponíveis
              </Link>
            </Grow>
          </li>
          <li>
            <Grow
              in={navLinksAnimState.gallery}
              timeout={animTimeout}
              onEntered={() => triggerNextAnim("contact")}
            >
              <Link
                className="link"
                to="/galeria"
                onClick={closeMobileMenu}
              >
                Galeria
              </Link>
            </Grow>
          </li>

          <li>
            <Grow in={navLinksAnimState.contact} timeout={animTimeout}>
              <Link className="link" to="/?section=contato" onClick={closeMobileMenu}>
                Contato
              </Link>
            </Grow>
          </li>
        </ul>
      </div>
      <Divider sx={{ background: "#303030" }} />
    </NavbarContainer>
  );
}

// Hook
function useOnClickOutside(ref: any, handler: any) {
  useEffect(() => {
    const listener = (event: any) => {
      // Do nothing if clicking ref's element or descendent elements
      if (!ref.current || ref.current.contains(event.target)) {
        return;
      }
      handler(event);
    };
    document.addEventListener("mousedown", listener);
    document.addEventListener("touchstart", listener);
    return () => {
      document.removeEventListener("mousedown", listener);
      document.removeEventListener("touchstart", listener);
    };
  }, [ref, handler]);
}

function backToTop() {
  document.body.scrollTop = 0;
  document.documentElement.scrollTop = 0;
}
