import { styled } from "@mui/system";
import { RatazimaTheme } from "../../../Theme";
import { Form } from "@unform/web";
import Input from "../../../common/Form/input";
import { useRef, useState } from "react";
import { FormHandles, SubmitHandler } from "@unform/core";
import * as Yup from "yup";
import chroma from "chroma-js";
import MailIcon from "@mui/icons-material/Mail";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

import Button from "@mui/material/Button";
import axios from "axios";

import DialogModal from "../../../common/DialogModal";

interface iProps {
  title?: string;
  subtitle?: string;
  description?: string;
}

export default function ContatoHome(props: iProps) {
  const formRef = useRef<FormHandles>(null);
  const [open, setOpen] = useState<boolean>(false);
  const { title, subtitle, description } = props;

  const handleWhatsappSubmit = async (data: any) => {
    const { name, email, phone, message } = data;
    const whatsapp = `https://wa.me/5543996834944?text=Nome:%20${name}.%20Email:%20${email}.%20Mensagem:%20${message}`;
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required("Por favor, informe seu nome"),
        email: Yup.string()
          .email("Por favor, informe um email válido")
          .required("Por favor, informe seu email"),
        message: Yup.string().required("Por favor, digite uma mensagem"),
      });

      await schema.validate(data, { abortEarly: false });
      window.open(whatsapp);

      // send the form data to the server asynchronously and log the response, then, clear the form and the errors
      await axios.post(
        "https://us-central1-site-ratazima.cloudfunctions.net/hello/contact/general",
        data
      );
      // setOpen(true);
      formRef.current?.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errorMessages = {};

        err.inner.forEach((e) => {
          errorMessages = { ...errorMessages, [e.path!]: e.message };
        });

        formRef.current!.setErrors(errorMessages);
      }
    }
  };

  const handleSubmit: SubmitHandler = async (data: any, { reset }) => {
    try {
      const schema = Yup.object().shape({
        name: Yup.string().required("Por favor, informe seu nome"),
        phone: Yup.string().required("Por favor, informe seu telefone"),
        email: Yup.string()
          .email("Por favor, informe um email válido")
          .required("Por favor, informe seu email"),
        message: Yup.string().required("Por favor, digite uma mensagem"),
      });

      await schema.validate(data, { abortEarly: false });

      console.log({ ...data });

      // send the form data to the server asynchronously and log the response, then, clear the form and the errors
      await axios.post(
        "https://us-central1-site-ratazima.cloudfunctions.net/hello/contact/general",
        data
      );
      reset();
      setOpen(true);
      formRef.current?.setErrors({});
    } catch (err) {
      if (err instanceof Yup.ValidationError) {
        let errorMessages = {};

        err.inner.forEach((e) => {
          errorMessages = { ...errorMessages, [e.path!]: e.message };
        });

        formRef.current!.setErrors(errorMessages);
      }
    }
  };

  return (
    <ContatoHomeContainer id="contato-section">
      <DialogModal
        open={open}
        setOpen={setOpen}
        title="Mensagem enviada!"
        text="Obrigado pela mensagem, o tatuador entrará em contato em breve!"
      />
      <div className="contato-home-content-wraper">
        <div className="contato-home-texto">
          <div className="contato-home-title-wraper">
            <h1 className="contato-home-title">
              {!title ? (
                <>
                  ENTRAR EM <br /> <span>CONTATO</span>
                </>
              ) : (
                <>
                  {title} <br /> <span>{subtitle}</span>
                </>
              )}
            </h1>
          </div>
          <p className="contato-home-text">
            {!description ? (
              <>
                Caso tenha alguma dúvida sobre meu trabalho, preencha os campos
                e envie sua mensagem.
              </>
            ) : (
              description
            )}
          </p>
        </div>
        <div className="contato-home-form">
          <Form
            onSubmit={handleSubmit}
            ref={formRef}
            placeholder={undefined}
            onPointerEnterCapture={undefined}
            onPointerLeaveCapture={undefined}
          >
            <div>
              <Input label="Nome" name="name" id="nome-contato"></Input>
              <Input label="E-mail" name="email"></Input>
              <Input label="Celular" name="phone"></Input>
              <Input
                label="Mensagem"
                name="message"
                multiline
                minRows={2}
              ></Input>
            </div>
            <div style={{ width: "100%", display: "flex" }}>
              <Button
                endIcon={<MailIcon />}
                type="submit"
                variant="contained"
                color="secondary"
                size="medium"
                fullWidth
                sx={{
                  marginTop: "1rem",
                  marginRight: ".5rem",
                  marginLeft: "0rem",
                }}
              >
                Enviar
              </Button>
              <Button
                id="whatsapp-button"
                endIcon={<WhatsAppIcon />}
                variant="outlined"
                color="success"
                size="medium"
                fullWidth
                onClick={() => {
                  handleWhatsappSubmit(formRef.current?.getData());
                }}
                sx={{ marginTop: "1rem", marginRight: "0" }}
              >
                Enviar
              </Button>
            </div>
          </Form>
        </div>
      </div>
    </ContatoHomeContainer>
  );
}

const ContatoHomeContainer = styled("section")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  overflow: hidden;
  width: 100%;

  /* border: 1px solid white; */

  .contato-home-content-wraper {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    width: 100%;
    max-width: ${RatazimaTheme.dimensions.maxColumnWidth};
    height: 100%;

    --contato-home-ratio: 50%;

    .contato-home-form {
      width: calc(100% - var(--contato-home-ratio));
      Button {
        margin-bottom: 1rem;
        &:hover {
          //add outer glow effect
          box-shadow: 0px 0px 10px 2px
            ${(props) => chroma("#000").alpha(0.8).hex()};
        }
      }
    }

    .contato-home-texto {
      color: ${(props) => props.theme.palette.primary.main};
      text-align: left;
      width: min-content;
      margin-right: 3rem;
      margin-top: 1rem;
      font-size: 20px;

      .contato-home-title-wraper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;

        .contato-home-title {
          width: min-content;
          white-space: nowrap;
          position: relative;
          text-align: center;
          font-size: calc(${RatazimaTheme.fontSizes.mainTitles} * 1);
          font-weight: 400;
          margin: 0 0 2rem 0;

          span {
            font-size: calc(${RatazimaTheme.fontSizes.mainTitles} * 1.2);
            font-weight: 800;
          }

          &:after {
            position: absolute;
            z-index: 2;
            content: "";
            height: 3px;
            right: 108%;
            left: -50vw;
            bottom: 25%;
            /* width: 100px; */
            background: ${(props) => props.theme.palette.secondary.main};
          }
        }
      }
    }

    @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
      .contato-home-texto {
        .contato-home-text {
          text-align: center;
        }
        .contato-home-title-wraper {
          .contato-home-title {
            font-size: calc(1 * ${RatazimaTheme.fontSizes.mainTitles});
            span {
              font-size: calc(1 * ${RatazimaTheme.fontSizes.mainTitles});
            }
            &:before {
              position: absolute;
              z-index: 2;
              content: "";
              height: 3px;
              left: 108%;
              right: -50vw;
              bottom: 25%;
              /* width: 100px; */
              background: ${(props) => props.theme.palette.secondary.main};
            }
          }
        }
        margin-right: 0;
        margin-bottom: 3rem;
      }
      .contato-home-form {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        Form {
          width: 95%;
          Input {
            margin: 0;
          }
        }
      }
    }
  }
`;
