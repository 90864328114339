import React from "react";
import { getStorage, ref, uploadBytes, updateMetadata, listAll, getDownloadURL, deleteObject } from "firebase/storage";
import app from "./firebase";

const storage = getStorage(app);



export async function uploadFiles(filesToUpload: Array<any>, path: string) {

  const arrayToUpload = Array.from(filesToUpload).sort((a, b) => a.name.localeCompare(b.name));

  return await Promise.all(arrayToUpload.map(async (file: any, index: number) => {
    const fileRef = ref(
      storage,
      path + "/" + file.name
    );
    const metadata = {
      customMetadata: {
        index: index.toString(),
      },
    };

    await uploadBytes(fileRef, file)
    return await updateMetadata(fileRef, metadata);
  }));

}

export async function getFilesURLs(path: string) {
  const listRef = ref(storage, path);
  const res = await listAll(listRef);

  const filesURLs = await Promise.all(res.items.map(async (itemRef) => {

    return await getDownloadURL(itemRef);
  }));

  return filesURLs;
}

export async function deleteFiles(path: string) {
  const folderRef = ref(storage, path)
  const fileList = await listAll(folderRef)
  return await Promise.all(fileList.items.map(async (fileRef) => {
    return await deleteObject(fileRef)
  }));

}