import React from 'react';

export default function About() {
  return (
    <div className="w-full flex items-center justify-center">
      <div className="text-white text-xl leading-relaxed my-32 max-w-2xl">
        <h1 className="text-4xl font-bold mb-8">Rafael Tazima (Ratazima)</h1>

        <p>
          Olá, eu sou Rafael Tazima, mais conhecido como Ratazima. Nascido em
          Londrina-PR, e descendente de uma família japonesa. Desde criança,
          sempre tive uma forte conexão com o desenho. O hábito de desenhar foi
          algo que me acompanhou ao longo da vida e, com o tempo, evoluiu para
          uma forma de expressão artística que acabou me levando ao mundo da
          tatuagem.
          <br />
          <br />
          A influência da minha herança cultural também desempenha um papel
          importante no meu trabalho. O estilo de tatuagem oriental, com seus
          elementos simbólicos e detalhados, como dragões, carpas e flores de
          cerejeira, ressoa profundamente comigo. Essa ligação com a tradição
          visual japonesa é algo que sempre esteve presente, sendo parte do meu
          convívio familiar, e hoje é uma das principais inspirações no meu
          trabalho como tatuador.
          <br />
          <br />
          Minha trajetória na tatuagem começou oficialmente em 2015, quando
          comecei a explorar o desenho aplicado à pele e fiz minhas primeiras
          tatuagens em amigos, enquanto concluía o Bacharelado em Design na
          UTFPR. Durante o curso, além de desenvolver minha técnica no desenho,
          também trabalhei com fabricação de móveis, cerâmicas e participei de
          diversos projetos de design gráfico. Essas experiências foram
          essenciais para moldar minha visão artística e influenciam diretamente
          meu trabalho até hoje. No meu Trabalho de Conclusão de Curso (TCC),
          explorei a criação de estampas e padrões para tecidos e roupas, o que
          me permitiu expandir ainda mais minha visão sobre o design aplicado,
          algo que continua a influenciar meu trabalho na tatuagem.
          <br />
          <br />
          Em 2017, após me formar, comecei a trabalhar em estúdios de Curitiba,
          onde pude aprimorar minhas habilidades e desenvolver meu estilo
          próprio, sempre com um olhar atento às histórias e expectativas de
          cada cliente. O tempo em estúdios foi crucial para o meu crescimento
          artístico e me ajudou a definir um estilo que muitas vezes traz
          influências orientais, misturadas com elementos contemporâneos. Em
          2022, realizei o sonho de abrir meu próprio estúdio privado.
          <br />
          <br />
          Esse espaço é a concretização de anos de trabalho e aprendizado, e
          nele posso oferecer uma experiência personalizada para cada cliente.
          Embora meu estúdio esteja em Curitiba, também atendo em Londrina e São
          Paulo, com planos de expandir para outras cidades no futuro. Minha
          jornada tem sido marcada por muito aprendizado, desafios e
          crescimento. O que realmente me motiva é a oportunidade de transformar
          ideias e histórias em arte, especialmente quando posso integrar
          elementos da tradição oriental que fazem parte da minha história e da
          minha paixão por desenhar, algo que me acompanha desde a infância.
          <br />
          <br />
          Cada tatuagem é única, e esse processo criativo é o que me faz
          continuar evoluindo e buscando novas inspirações.
        </p>
      </div>
    </div>
  );
}
