import { styled } from "@mui/system";

export const LoginContainer = styled("section")`
  width: 100%;
  min-height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;


  .login-form {
    padding: 1rem 2rem;
    display: flex;
    flex-direction: column;
    width: 300px;

    .login-field{
      margin-top: 1rem;
      input:-webkit-autofill{
        box-shadow: 0 0 0 30px ${props=> props.theme.palette.grey["900"]} inset;
        -webkit-box-shadow: 0 0 0 30px ${props=> props.theme.palette.grey["900"]} inset;
      }
    }
  }
`;