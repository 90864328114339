import React, { useEffect } from "react";
import { Routes, Route } from "react-router-dom";
import "./styles/global.css";
import "./index.css";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { AuthProvider } from "./contexts/AuthContext";
import MainRoutes from "./Routes";

const theme = createTheme({
  palette: {
    mode: "dark",
    primary: {
      main: "#E7E4DF",
    },
    secondary: {
      main: "#31098f",
    },
  },
});

function App() {
  useEffect(() => {
    console.log({ theme });
  }, []);
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <AuthProvider>
          <MainRoutes />
        </AuthProvider>
      </ThemeProvider>
    </div>
  );
}

export default App;
