export const SiteContent = {
  disponiveis: {
    title: {
      main: "DISPONÍVEIS",
      sub: "DESENHOS",
    },
    description:
      "Aqui você pode visualizar e reservar meus desenhos disponíveis. Cada desenho acompanha uma sugestão de posicionamento, mas é possível alterar o local do corpo a ser tatuado. Conforme a necessidade, o tamanho dos desenhos pode ser alterado para se encaixar no corpo do cliente.",
    text: "",
  },

  galeria: {
    title: {
      main: "GALERIA",
      sub: "PROJETOS CONCLUÍDOS",
    },
    description:
     "Confira aqui algumas tatuagens finalizadas. É possível criar uma tatuagem exclusiva utilizando esses projetos concluídos como referência.",
    text: "",
  },

  brokenHearts: {
    title: {
      main: "DISPONÍVEIS",
      sub: "CORAÇÕES",
    },
    description: "São mais de 70 opções de corações para serem tatuados. Os desenhos têm cerca de 6 centímetros e se encaixam bem em qualquer lugar do corpo. É possível alterar ou retirar a frase, e adicionar cores. Os corações são desenhos não-exclusivos, e podem ser tatuados em mais de um cliente."
  }
};
