import React from 'react';
import {
  Accordion,
  AccordionHeader,
  AccordionBody,
} from '@material-tailwind/react';

const faq = [
  {
    question: 'Como faço para agendar uma consulta ou sessão de tatuagem?',
    answer:
      'Para agendar uma consulta ou sessão, você pode preencher o formulário de solicitação de orçamento no site. Após enviar suas informações, entraremos em contato para discutir os detalhes e agendar a data mais conveniente.',
  },
  {
    question: 'Quanto custa uma tatuagem?',
    answer:
      'O valor de uma tatuagem varia de acordo com o tamanho, complexidade, localização no corpo e estilo. O ideal é preencher nosso formulário de orçamento para receber uma estimativa personalizada.',
  },
  {
    question: 'Você faz desenhos personalizados?',
    answer:
      'Sim! Todos os desenhos são criados de forma personalizada com base na sua ideia e estilo preferido. Trabalhamos com exclusividade, garantindo que sua tatuagem seja única.',
  },
  {
    question: 'Quais estilos de tatuagem você trabalha?',
    answer:
      'Nosso foco principal está em quatro estilos: Neo Oriental, Japonês Tradicional (Wabori), Blackwork e Geométrico. Cada estilo é adaptado às preferências e características do cliente.',
  },
  {
    question: 'Qual o processo de criação de uma tatuagem?',
    answer:
      'Após a consulta inicial, o desenho é desenvolvido e revisado com o cliente. Em projetos grandes, o trabalho pode ser dividido em várias sessões. O processo detalhado pode ser conferido na página de “Processo de Criação”.',
  },
  {
    question: 'A tatuagem dói?',
    answer:
      'A dor varia de pessoa para pessoa e também depende da localização da tatuagem. Algumas áreas do corpo são mais sensíveis do que outras, mas aplicamos técnicas para garantir o máximo de conforto durante a sessão.',
  },
  {
    question: 'Quanto tempo leva para fazer uma tatuagem?',
    answer:
      'O tempo de execução depende do tamanho e da complexidade da tatuagem. Tatuagens pequenas podem ser concluídas em uma única sessão, enquanto projetos maiores podem exigir várias sessões.',
  },
  {
    question: 'Como devo cuidar da minha tatuagem após a sessão?',
    answer:
      'Depois da sessão, forneceremos todas as instruções de cuidados pós-tatuagem, incluindo a limpeza adequada e a aplicação de pomada. Esses cuidados são essenciais para garantir que a tatuagem cicatrize bem e mantenha sua qualidade.',
  },
  {
    question: 'Você faz retoques?',
    answer:
      'Sim, oferecemos retoques caso necessário. Recomendamos fazer um retoque após a cicatrização inicial, se houver áreas que precisem de ajustes.',
  },
  {
    question: 'Qual é a idade mínima para fazer uma tatuagem?',
    answer:
      'A idade mínima para fazer uma tatuagem é 18 anos. Não tatuamos menores de idade, mesmo com a autorização dos pais.',
  },
  {
    question: 'É possível remover uma tatuagem?',
    answer:
      'Não realizamos remoção de tatuagem no estúdio. Para remoção, recomendamos procurar um especialista em tratamentos a laser.',
  },
  {
    question: 'O que devo fazer antes de uma sessão de tatuagem?',
    answer:
      'É importante chegar bem hidratado e alimentado para a sessão. Evite beber álcool ou consumir qualquer substância que afete a coagulação do sangue nas 24 horas anteriores à tatuagem.',
  },
  {
    question: 'Quais métodos de pagamento vocês aceitam?',
    answer:
      'Aceitamos pagamento em dinheiro, cartão de débito, crédito e PIX. Pagamentos podem ser feitos em partes, caso o projeto seja dividido em várias sessões.',
  },
  {
    question: 'Posso levar um acompanhante para a sessão?',
    answer:
      'Sim, você pode trazer um acompanhante, mas pedimos que seja apenas uma pessoa para garantir a tranquilidade no estúdio e manter o foco no processo de tatuagem.',
  },
];

export default function FAQ() {
  const [open, setOpen] = React.useState(1);

  const handleOpen = (value: number) => setOpen(open === value ? 0 : value);
  return (
    <div className="w-full flex items-center justify-center">
      <div className="text-white text-xl leading-relaxed my-32 max-w-2xl">
        <h1 className="text-4xl font-bold mb-8">Perguntas Frequentes</h1>
        <br />
        <br />
        <Accordion
          open={open === 1}
          placeholder="" // Add default or appropriate value
          onPointerEnterCapture={() => {}} // Add appropriate handler
          onPointerLeaveCapture={() => {}} // Add appropriate handler
        >
          <AccordionHeader
            onClick={() => handleOpen(1)}
            placeholder="" // Add default or appropriate values
            onPointerEnterCapture={() => {}} // Add default or appropriate handlers
            onPointerLeaveCapture={() => {}} // Add default or appropriate handlers
          >
            What is Material Tailwind?
          </AccordionHeader>
          <AccordionBody>
            asdsadsa
          </AccordionBody>
        </Accordion>
      </div>
    </div>
  );
}
