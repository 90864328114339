import { styled } from "@mui/system";
import { RatazimaTheme } from "../../../Theme";
import VerticalSpacer from "../../../common/VerticalSpacer";
import { useEffect, useRef, useState } from "react";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepContent from "@mui/material/StepContent";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import CheckIcon from "@mui/icons-material/Check";
// import Fade from "@mui/material/Fade";

import video_processo_01 from "../../../videos/processo_01_new.mp4";
import video_processo_02 from "../../../videos/processo_02_new.mp4";
// import video_processo_03 from "../../../videos/processo_03_new.mp4";

import chroma from "chroma-js";

const QontoStepIconRoot = styled("div")<{ ownerState: { active?: boolean } }>(
  ({ theme, ownerState }) => ({
    color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
    display: "flex",
    height: 22,
    alignItems: "center",
    ...(ownerState.active && {
      color: theme.palette.secondary.main,
    }),
    "& .QontoStepIcon-completedIcon": {
      color: theme.palette.secondary.main,
      zIndex: 1,
      fontSize: 18,
    },
    "& .QontoStepIcon-circle": {
      width: 8,
      height: 8,
      borderRadius: "50%",
      backgroundColor: "currentColor",
    },
  })
);
function QontoStepIcon(props: StepIconProps) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <CheckIcon className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

const QontoConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: theme.palette.secondary.main,
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 0,
    padding: 0,
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderLeftWidth: 3,
    borderRadius: 1,
    margin: "10px",
    display: "none",
  },
}));

//create an array of steps with a text and a url
const steps = [
  {
    title: "Reunião Inicial",
    text: "Projetos grandes requerem uma reunião inicial para visualizarmos o local do corpo a ser tatuado, o tamanho e a composição do desenho para que seja encaixado perfeitamente. Cada corpo é diferente e cada curva deve ser observada para que o resultado final fique harmonioso. Nessa primeira reunião são feitos esboços no corpo do cliente para visualizarmos a ideia principal, e em seguida realizo um molde com o formato da região e o esboço encaixado. Além disso, são visualizadas referências e discutimos os detalhes para que o projeto comece a se concretizar.",
    video: video_processo_01,
  },
  {
    title: "As sessões",
    text: "O dia da sua sessão é exclusivo para você, uma vez que não atendo mais de um cliente por dia. Solicito que não marque nenhum compromisso no dia da sua sessão, pois ela pode demorar horas e qualquer interferência pode influenciar negativamente no resultado final. Não se esqueça de vir bem alimentado e de preferência com roupas pretas. O número de sessões varia de projeto para projeto.",
    video: video_processo_02,
  },
  // {
  //   title: "Terceira etapa",
  //   text: "O dia da sua sessão é exclusivo para você, uma vez que não atendo mais de um cliente por dia. Solicito que não marque nenhum compromisso no dia da sua sessão, pois ela pode demorar horas e qualquer interferência pode influenciar negativamente no resultado final. Não se esqueça de vir bem alimentado e de preferência com roupas pretas. O número de sessões varia de projeto para projeto.",
  //   video: video_processo_03,
  // },
];

export default function ProcessoHome() {
  const [activeStep, setActiveStep] = useState(0);

  const videoRef = useRef<HTMLVideoElement>(null);

  useEffect(() => {
    videoRef.current?.load();
  }, [activeStep]);

  useEffect(() => {
    //set a timer to cycle through the steps
    const interval = setInterval(() => {
      // setActiveStep((activeStep) => (activeStep + 1) % steps.length);
    }, 15000);
    return () => clearInterval(interval);
  }, []);

  return (
    <ProcessoHomeContainer>
      <div className="processo-home-content-wraper">
        <div className="processo-home-texto">
          <div className="processo-home-title-wraper">
            <h1 className="processo-home-title">
              MEU <span style={{ fontWeight: 800 }}>PROCESSO</span>
            </h1>
          </div>
          <p className="processo-home-text">
            A maior parte dos meus trabalhos são personalizados, encomendados
            pelos meus clientes. A ideia a ser tatuada pode ser enviada através
            da solicitação de orçamento e em seguida iniciamos o desenvolvimento
            do projeto.
          </p>
        </div>

        <VerticalSpacer height="3rem" />

        <ProcessoHomeCard>
          <div className="processo-home-image">
            <video ref={videoRef} autoPlay muted loop playsInline>
              <source src={steps[activeStep].video} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
          </div>
          <div className="processo-home-steps">
            <Stepper
              activeStep={activeStep}
              orientation="vertical"
              connector={<QontoConnector />}
              sx={{ alignItems: "center" }}
            >
              {steps.map((step, index) => (
                <Step
                  key={step.title}
                  sx={{ width: "100%", margin: "10px 0" }}
                  onClick={() => setActiveStep(index)}
                >
                  <StepLabel
                    StepIconComponent={QontoStepIcon}
                    className={index === activeStep ? "meu-conector" : ""}
                  >
                    <CustomLabel>{step.title}</CustomLabel>
                  </StepLabel>
                  <StepContent
                    sx={{ borderLeft: "2px solid white", width: "100%" }}
                  >
                    <p
                      className="processo-home-main-text"
                      style={{ color: "white" }}
                    >
                      {step.text}
                    </p>
                  </StepContent>
                </Step>
              ))}
            </Stepper>
          </div>
        </ProcessoHomeCard>
      </div>
    </ProcessoHomeContainer>
  );
}

const CustomLabel = styled("h2")`
  cursor: pointer;
  &:hover {
    color: ${(props) => props.theme.palette.secondary.main};
  }
`;

const ProcessoHomeContainer = styled("section")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: 100%;
  overflow: hidden;
  width: 100%;

  /* border: 1px solid white; */

  .processo-home-content-wraper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    max-width: ${RatazimaTheme.dimensions.maxColumnWidth};
    height: 100%;

    --bio-home-ratio: 50%;

    .processo-home-texto {
      color: ${(props) => props.theme.palette.primary.main};
      text-align: center;

      .processo-home-title-wraper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .processo-home-title {
          width: min-content;
          white-space: nowrap;
          position: relative;
          font-size: ${RatazimaTheme.fontSizes.mainTitles};
          font-weight: 400;
          margin: 0 0 2rem 0;
          &:after {
            position: absolute;
            content: "";
            height: 3px;
            left: 0;
            right: 0;
            bottom: -8px;
            /* width: 100px; */
            background: ${(props) => props.theme.palette.secondary.main};
          }
        }
      }
      .processo-home-text {
        margin-top: 2rem;
        padding: 0 3rem;
      }
    }
  }

  @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
    .processo-home-texto {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .processo-home-title-wraper {
        .processo-home-title {
          font-size: calc(${RatazimaTheme.fontSizes.mainTitles} * 0.9);
          &:after {
          }
        }
      }
      .processo-home-text {
        width: 85%;
        margin-top: 2rem;
        padding: 0;
      }
    }
  }
`;

const ProcessoHomeCard = styled("div")`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  margin-bottom: 2rem;
  background: ${(props) => chroma("#000").alpha(0.5).hex()};
  border-radius: 12px;
  /* border: 2px solid ${(props) => props.theme.palette.secondary.main}; */
  box-shadow: 5px 5px 30px rgba(0, 0, 0, 0.2);
  /* border: 1px solid blue; */

  .processo-home-steps {
    /* border: 1px solid white; */
    width: 65%;

    @keyframes grow-left {
      from {
        right: 150%;
      }
      to {
        right: 100%;
      }
    }

    .meu-conector {
      position: relative;
      &:after {
        position: absolute;
        content: "";
        height: 3px;
        left: -100px;
        right: 100%;
        /* bottom: 50%; */
        top: calc(50% - 1.5px);
        /* width: 100px; */
        background: ${(props) => props.theme.palette.secondary.main};
        animation: grow-left;
        animation-duration: 0.75s;
        animation-timing-function: ease-in-out;
      }
    }
  }

  width: 100%;
  .processo-home-image {
    /* border: 1px solid red; */

    z-index: 20;
    video {
      background-color: black;
      border-radius: 8px;
      width: calc(108vh * 0.3);
      height: calc(135vh * 0.3);
      border: 3px solid ${(props) => props.theme.palette.secondary.main};
    }
    position: relative;
  }

  .processo-home-main-text {
    /* border: 1px solid white; */
    display: flex;
    align-items: center;
    justify-content: right;
    text-align: left;
    width: 80%;
    margin-right: 3rem;
    color: ${(props) => props.theme.palette.primary.main};

    p {
      width: 50%;
      font-weight: 300;
    }
  }

  @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
    flex-direction: column;
    align-items: center;
    width: 100%;
    .processo-home-steps {
      width: 100%;
    }

    .processo-home-image {
      width: 100%;
      display: flex;
      justify-content: center;
      /* padding-top: 80%; */
    }

    .processo-home-main-text {
      width: 100%;
      text-align: left;
    }
  }
`;
