import React from 'react';

export default function StylesOfTattoo() {
  return (
    <div className="w-full flex items-center justify-center">
      <div className="text-white text-xl leading-relaxed my-32 max-w-2xl">

        <h1 className="text-4xl font-bold mb-8">
          Estilos de Tatuagem no Estúdio Ratazima Tattoo
        </h1>
        <br />
        <br />
        <p>
          Rafael Tazima se especializa em quatro estilos principais de tatuagem:
          Neo Oriental, Japonês Tradicional, Blackwork e Geométrico. Cada um
          desses estilos tem suas características únicas e reflete uma
          combinação de influências culturais e técnicas que fazem parte da
          visão artística de Rafael. A seguir, explicamos em mais detalhes esses
          estilos e como eles se manifestam nas criações feitas no estúdio.
        </p>
        <br />
        <br />
        <h2 className="text-2xl font-bold mb-4">Neo Oriental</h2>
        <p>
          O estilo Neo Oriental é uma releitura moderna das tatuagens
          tradicionais orientais, especialmente das japonesas. Enquanto mantém
          muitos dos elementos tradicionais, como dragões, carpas, tigres,
          flores de cerejeira e ondas, o Neo Oriental traz uma abordagem mais
          contemporânea, incorporando detalhes ousados e variações na
          composição. No estúdio Ratazima, o Neo Oriental permite que Rafael
          expanda as fronteiras da tatuagem tradicional japonesa, combinando a
          simbologia e a estética clássica com técnicas mais modernas, como
          sombreamentos e gradientes. Esse estilo é perfeito para quem quer
          manter a conexão com a cultura oriental, mas busca uma aparência mais
          inovadora e atual. Cada peça no estilo Neo Oriental é criada com
          cuidado para respeitar as proporções do corpo, aproveitando o
          movimento e a fluidez da pele, resultando em uma obra de arte que se
          integra harmoniosamente com a anatomia de cada cliente.
        </p>
        <br />
        <br />
        <h2 className="text-2xl font-bold mb-4">Japonês Tradicional (Wabori)</h2>
        <p>
          O estilo Japonês Tradicional, também conhecido como Wabori, é uma
          forma de arte profundamente enraizada na cultura japonesa. Este estilo
          é caracterizado pelo uso de linhas grossas e bem definidas, e temas
          simbólicos como criaturas mitológicas (dragões, fênix), animais
          (carpas, tigres), flores (peônias, cerejeiras) e figuras de guerreiros
          ou divindades. Cada elemento possui um significado profundo, muitas
          vezes relacionado à força, coragem, proteção e renovação. No estúdio
          Ratazima, Rafael honra as proteção e renovação. No estúdio Ratazima,
          Rafael honra as tradições dessa forma de arte milenar, criando
          tatuagens que seguem os princípios clássicos do Wabori, mas com um
          toque personalizado para refletir a história e os desejos de cada
          cliente. As peças no estilo Japonês Tradicional geralmente cobrem
          grandes áreas do corpo, como as costas, os braços ou as pernas, e são
          projetadas para se movimentar com o corpo de forma natural,
          respeitando o fluxo e a simetria do desenho. O resultado é uma
          tatuagem rica em significado, que carrega a herança e a
          espiritualidade da cultura japonesa.
        </p>
        <br />
        <br />
        <h2 className="text-2xl font-bold mb-4" >Blackwork</h2>
        <p>
          O estilo Blackwork é conhecido por sua simplicidade poderosa. Ele se
          baseia no uso exclusivo de tinta preta para criar desenhos
          impactantes, preenchimentos sólidos, sombreamento denso e, às vezes,
          elementos geométricos ou abstratos. Ao contrário de estilos mais
          coloridos, o Blackwork foca no contraste e no uso da ausência de cor
          para gerar profundidade e intensidade visual. Rafael domina essa
          técnica, utilizando o Blackwork tanto em peças minimalistas quanto em
          projetos maiores, onde o contraste entre a pele e a tinta preta gera
          uma estética marcante e contemporânea. Além disso, ele incorpora
          elementos orgânicos e geométricos no Blackwork, o que possibilita a
          criação de desenhos versáteis e extremamente personalizados, sempre
          adaptados ao formato do corpo de cada cliente. O Blackwork é uma
          escolha ideal para aqueles que buscam uma tatuagem com alto impacto
          visual e um design que perdure com o tempo, mantendo sua integridade
          estética mesmo após anos.
        </p>
        <br />
        <br />
        <h2 className="text-2xl font-bold mb-4">Geométrico</h2>
        <p>
          O estilo Geométrico é conhecido pela sua precisão e simetria. Composto
          por formas geométricas simples ou complexas, esse estilo utiliza
          linhas finas e bem definidas, padrões repetitivos e elementos
          abstratos para criar uma estética única. O foco está em criar harmonia
          visual por meio da matemática das formas, o que resulta em tatuagens
          que se destacam por sua clareza e ordem. Rafael utiliza o estilo
          Geométrico de forma criativa, integrando padrões detalhados que se
          adaptam perfeitamente ao formato do corpo. Esse estilo pode ser
          aplicado tanto em peças menores quanto em projetos maiores e pode ser
          combinado com outros estilos, como Blackwork, para gerar um efeito
          ainda mais impactante. O estilo Geométrico é ideal para quem procura
          uma tatuagem que transmita simplicidade, equilíbrio e precisão.
        </p>
        <br />
        <br />
        <h2 className="text-2xl font-bold mb-4">Versatilidade e Criatividade no Estúdio Ratazima Tattoo</h2>
        <p>
          No estúdio Ratazima Tattoo, cada um desses estilos é abordado com a
          mesma dedicação e atenção aos detalhes. Rafael trabalha de maneira
          colaborativa com seus clientes, garantindo que o estilo escolhido se
          alinhe perfeitamente à visão de cada pessoa e que o resultado final
          seja uma obra de arte única. Seja você fã da estética vibrante do Neo
          Oriental, da tradição simbólica do Wabori (Japonês Tradicional), do
          impacto visual do Blackwork ou da precisão do Geométrico, o estúdio
          oferece um ambiente onde a criatividade encontra a técnica, e cada
          tatuagem é projetada para contar uma história.
        </p>
      </div>
    </div>
  );
}
