import React from 'react';

function Studio() {
  return (
    <div>
      <h1>Our Studio</h1>
      <p>This is the studio page of our website.</p>
    </div>
  );
}

export default Studio;