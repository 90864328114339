import { styled } from "@mui/system";
import { RatazimaTheme } from "../../Theme";
import VerticalSpacer from "../../common/VerticalSpacer";
import ImageListItem from "@mui/material/ImageListItem";
import { useEffect, useState } from "react";
import { getAvailableTattoos } from "../../firebase/firestore";
import CardMedia from "@mui/material/CardMedia/CardMedia";
import Button from "@mui/material/Button";
import ButtonGroup from "@mui/material/ButtonGroup";
import useWindowDimensions from "../../Theme/WindowDimensions";
import ListaTags from "../../common/ListaTags";
import Card from "@mui/material/Card";
import { useNavigate, useParams } from "react-router-dom";
import { SiteContent } from "../../contexts/SiteContent";


export default function DisponiveisCoracoes() {
  const [tattoos, setTattoos] = useState<Array<any>>([]);
  const [allTattoos, setAllTattoos] = useState<Array<any>>([]);
  const { width, height } = useWindowDimensions();
  // const [tagFilter, setTagFilter] = useState("");
  const [alltags, setAllTags] = useState<Array<string>>([]);
  const { tag } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    //set the document title
    document.title = "Ratazima | Tatuagens Disponíveis";
    window.scrollTo(0, 0);
    getAvailableTattoos().then((_tattoos) => {
      _tattoos = _tattoos.filter((tattoo : any) => tattoo.tags.includes("coração"));
      // setAllTattoos(_tattoos);
      // setAllTattoos(_tattoos.filter((tattoo : any) => !tattoo.tags.includes("coração")));
      const tags: Array<string> = _tattoos.map((item: any) => item.tags).flat();
      const trimTags = tags.map((item) => item.trim().toLowerCase());

      const _tags = ["todas", ...Array.from(new Set(trimTags))];
      setAllTags(_tags);

      if (tag && _tags.includes(tag)) {
        setTattoos(_tattoos.filter((item: any) => item.tags.includes(tag)));
        navigate("/disponiveis/" + tag);
      } else {
        // setTattoos(_tattoos);
        setTattoos(_tattoos.filter((tattoo : any) => tattoo.tags.includes("coração")));
      }
    });
  }, []);

  useEffect(() => {
    if (tag) {
      //tag === "todas" && navigate("/disponiveis/");
      filterTattoos(tag);
    }
  }, [tag]);

  function filterTattoos(tag: string) {
    if (alltags.includes(tag)) {
      const newArray = allTattoos.filter(
        (item) => item.tags.includes(tag) || tag === "todas"
      );
      setTattoos(newArray);
    } else {
      navigate("/disponiveis/");
      setTattoos(allTattoos);
    }
  }

  return (
    <DisponiveisHomeContainer>
      <div className="disponiveis-content-wraper">
        <div className="disponiveis-texto">
          <div className="disponiveis-title-wraper">
            <h1 className="disponiveis-title">
              {SiteContent.brokenHearts.title.sub}<br /> <span>{SiteContent.brokenHearts.title.main}</span>
            </h1>
          </div>
          <p className="disponiveis-text">
            {SiteContent.brokenHearts.description}
          </p>

          {/* <Card sx={{ padding: "1rem", margin: "2rem 0" }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <p style={{ marginRight: "1rem" }}>Ordenar por:</p>
              <ButtonGroup>
                <Button fullWidth onClick={()=> setTattoos([...tattoos.sort((a,b) => a.createdAt - b.createdAt)])}>Data</Button>
                <Button fullWidth onClick={()=> setTattoos([...tattoos.sort((a,b) => a.price - b.price)])}>Valor</Button>
                <Button fullWidth onClick={()=> setTattoos([...tattoos.sort((a,b) => a.title.localeCompare(b.title))])}>A-Z</Button>
              </ButtonGroup>
            </div>
            <TagsContainer>
              <ListaTags tags={alltags} activeTag={tag}/>
            </TagsContainer>
          </Card> */}
        </div>

        <VerticalSpacer height="3rem" />

        {/* <ImageList
          sx={{ width: "100%", height: "auto" }}
          cols={width! < 768 ? 2 : 3}
          gap={width! < 768 ? 6 : 14}
        > */}
          <div className="myImageList">

          
          {tattoos.map((item, index) => (
            <ImageListItem key={index}>
              <CardMedia
                style={{ height: "auto", paddingTop: "0" }}
                component="img"
                image={item.urls[0]}
                title="Tattoo"
                alt="tattoo"
              />
              <HoverCard
                url={item.urls[1]}
                position={item.position}
                zoom={item.zoom}
              >
                <div>
                  <p>{item.title}</p>
                  <p>{item.size}</p>
                  <h1>R$ {item.price}</h1>
                  <h2>Sugestão de aplicação: {item.place}</h2>
                  <Button
                    className="mais-info-button"
                    size="large"
                    fullWidth
                    variant="outlined"
                    href={`/disponiveis/id/${item.id}`}
                    color="secondary"
                    sx={{ marginTop: "10px" }}
                  >
                    Mais informações
                  </Button>
                </div>
              </HoverCard>
            </ImageListItem>
          ))}
          </div>
        {/* </ImageList> */}
        {/* <Button
          sx={{ marginTop: "1rem" }}
          fullWidth
          variant="contained"
          color="secondary"
          href="/disponiveis/"
        >
          Ver todos
        </Button> */}
      </div>
    </DisponiveisHomeContainer>
  );
}

const TagsContainer = styled("div")`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 1rem;

  a {
    margin: 3px;
  }

  #chip-tag-todas {
    color: white;
    background-color: black;
    font-weight: 700;
  }
`;

const HoverCard = styled("div")<{
  url: string;
  position: string;
  zoom: string;
}>`
  opacity: 0;
  position: absolute;
  background: url(${(props) => props.url});
  background-size: 100%;
  background-position: ${(props) => props.position};
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: all 0.3s ease-in-out;

  div {
    color: black;
    line-height: 1.2;
    background: linear-gradient(
      to top,
      rgba(255, 255, 255, 0.8),
      rgba(255, 255, 255, 0)
    );
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    padding: 1rem;
  }

  p {
    pointer-events: none;
    line-height: 1.5;
    font-size: 1.5rem;
  }

  h1 {
    pointer-events: none;
    color: ${(props) => props.theme.palette.grey["800"]};
    font-weight: 800;
  }
  h2 {
    pointer-events: none;
    font-size: 1rem;
    color: ${(props) => props.theme.palette.grey["800"]};
  }
  .mais-info-button {
    transition: pointer-events 0.3s ease-in-out;
    pointer-events: none;
  }

  &:hover {
    opacity: 1;
    background-size: ${(props) => props.zoom};
    .mais-info-button {
      animation-name: example;
      animation-duration: 0.5s;
      animation-fill-mode: both;
    }
  }

  @keyframes example {
    from {
      pointer-events: none;
    }
    to {
      pointer-events: all;
    }
  }

  @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
    p {
      font-size: 1rem;
      line-height: 1;
    }
    h1 {
      font-size: 1.2rem;
    }
    h2 {
      display: none;
    }
    div {
      padding: 0.4rem;
      .mais-info-button {
        padding: 0.4rem 0.5rem;
        font-size: 0.8rem;
        line-height: 1;
        text-align: center;
      }
    }

    &:hover {
      .mais-info-button {
        /* pointer-events: all; */
      }
    }
  }
`;

const DisponiveisHomeContainer = styled("section")`
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: 100%;
  overflow: hidden;
  width: 100%;
  padding: 0;
  margin: calc(${RatazimaTheme.dimensions.navBarHeight} + 5rem) 0;

  /* border: 1px solid white; */

  .disponiveis-content-wraper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: ${RatazimaTheme.dimensions.maxColumnWidth};
    height: 100%;

    --bio-home-ratio: 50%;

    .myImageList {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr 1fr;
      grid-gap: 8px;
      width: 100%;
    }

    .disponiveis-texto {
      color: ${(props) => props.theme.palette.primary.main};
      text-align: center;

      .disponiveis-title-wraper {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .disponiveis-title {
          width: min-content;
          white-space: nowrap;
          position: relative;
          font-size: 36px;
          font-weight: 400;
          margin: 0 0 1rem 0;

          span {
            font-size: 60px;
            font-weight: 800;
          }

          &:after {
            position: absolute;
            content: "";
            height: 3px;
            left: 0;
            right: 0;
            bottom: -8px;
            /* width: 100px; */
            background: ${(props) => props.theme.palette.secondary.main};
          }
        }
      }
      .disponiveis-text {
        margin-top: 2rem;
        padding: 0 3rem;
        font-weight: 300;
      }
    }

    @media only screen and (max-width: ${RatazimaTheme.breakpoints.tablet}) {
      .myImageList {
        width: 95%;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        grid-gap: 8px;
      }
      .disponiveis-texto {
        .disponiveis-title-wraper {
          .disponiveis-title {
            font-size: calc(.5* ${RatazimaTheme.fontSizes.mainTitles});
            span {
              font-size: calc(1* ${RatazimaTheme.fontSizes.mainTitles});
            }

            &:after {
            }
          }
        }
        .disponiveis-text {
        }
      }
    }
  }
`;
