import React from "react";
import Chip from "@mui/material/Chip";
import { useNavigate } from "react-router-dom";

export default function ListaTags({
  tags,
  activeTag,
}: {
  tags: Array<string>;
  activeTag?: string;
}) {
  const navigate = useNavigate();

  return (
    <>
      {tags.map(
        (tag, index) =>
          tag !== "esconder" && (
            <Chip
              id={`chip-tag-${tag}`}
              key={index}
              label={tag}
              component="a"
              clickable
              color={activeTag !== tag ? "primary" : "secondary"}
              sx={{ fontWeight: 600 }}
              onClick={() => navigate("/disponiveis/" + tag)}
            />
          )
      )}
    </>
  );
}
